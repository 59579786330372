import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2B417F",
      dark: "#fff",
      light: "#fff",
    },
    secondary: {
      main: "#F2AE14",
      dark: "#fff",
      light: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        "@media (min-width: 1280px)": {
          fontSize: "14px ",
        },
        "@media (min-width: 1920px)": {
          fontSize: "16px ",
        },
        "@media (min-width: 2048px)": {
          fontSize: "24px ",
        },
        "@media (min-width: 3840px)": {
          fontSize: "32px ",
        },
      },
    },
  },
});

import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function getWindowDimensions() {
  const { innerHeight: height, innerWidth: width } = window;
  return {
    height,
    width,
  };
}

export const Footer = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  //   useEffect(() => {
  //     function handleResize() {
  //       setWindowDimensions(getWindowDimensions());
  //     }

  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);
  return (
    <>
      <Grid container>
        {windowDimensions.width > 960 ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                pb: "1rem",
              }}
            >
              <Typography
                //variant="h4"
                sx={{ fontSize: "1rem", fontWeight: 600 }}
              >
                Connectionsgame.pro{" "}
                <span style={{ fontWeight: 600 }}>&#169; </span> 2023 | &nbsp;
              </Typography>
              <a
                href="https://megarama.net/cookie-policy-eu/"
                style={{ textDecoration: "none", color: "#000000" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                  Cookie Policy &nbsp;| &nbsp;
                </Typography>
              </a>
              <a
                href="https://megarama.net/privacy/"
                style={{ textDecoration: "none", color: "#000000" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                  Privacy Policy &nbsp;| &nbsp;
                </Typography>
              </a>
              <a
                href="https://megarama.net/terms-of-service/"
                style={{ textDecoration: "none", color: "#000000" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                  Terms & Conditions
                </Typography>
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                pb: "1rem",
              }}
            >
              <Typography sx={{ fontSize: "0.8rem", color: "#808080" }}>
                Connectionsgame.pro is not affiliated with “Connections” by
                NYTimes in any way.
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ mb: "0.5rem", fontSize: "1rem", fontWeight: 600 }}
                >
                  Connectionsgame.pro{" "}
                  <span style={{ fontWeight: 600 }}>&#169; </span> 2023 &nbsp;
                </Typography>
                <a
                  href="https://megarama.net/cookie-policy-eu/"
                  style={{ textDecoration: "none", color: "#000000" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    sx={{ mb: "0.5rem", fontSize: "1rem", fontWeight: 600 }}
                  >
                    Cookie Policy
                  </Typography>
                </a>
                <a
                  href="https://megarama.net/privacy/"
                  style={{ textDecoration: "none", color: "#000000" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    sx={{ mb: "0.5rem", fontSize: "1rem", fontWeight: 600 }}
                  >
                    Privacy Policy
                  </Typography>
                </a>
                <a
                  href="https://megarama.net/terms-of-service/"
                  style={{ textDecoration: "none", color: "#000000" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    sx={{ mb: "0.5rem", fontSize: "1rem", fontWeight: 600 }}
                  >
                    Terms & Conditions
                  </Typography>
                </a>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#808080",
                    justifyContent: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Connectionsgame.pro is not affiliated with “Connections” by
                  NYTimes in any way.
                </Typography>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

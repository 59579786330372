import { Home } from "./pages/Home";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/theme";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import SeoImage from "./assets/icon_legacy.png";

// const ID = "G-H8NJLF78QQ";
// ReactGA.initialize(ID);
// ReactGA.set({ debug: true });

function App() {
  // const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
  //   loaderUrl: "/assets/BallBlaster.loader.js",
  //   dataUrl: "/assets/BallBlaster.data",
  //   frameworkUrl: "/assets/BallBlaster.framework.js",
  //   codeUrl: "/assets/BallBlaster.wasm",
  // });

  // const handleClick = (e) => {
  //   ReactGA.event({
  //     category: "User Interaction",
  //     action: "Button Click",
  //     label: "Click me",
  //   });
  // };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Helmet>
          <title>Connections Game - Play Unlimited Games</title>
          <meta
            name="description"
            content="Play the Connections Game online with UNLIMITED levels! New challenging games are added each day. Find the correct connections between groups of words."
          />
          <meta name="keywords" content="connections game, connections" />
          <meta
            property="og:title"
            content="Connections Game - Play Unlimited Games"
          />
          <meta
            property="og:description"
            content="Play the Connections Game with UNLIMITED levels! New challenging games are added each day. Find the common connections between groups of words."
          />
          <meta property="og:image" content={SeoImage} />
          <meta
            name="twitter:title"
            content="Connections Game - Play Unlimited Games"
          />
          <meta
            name="twitter:description"
            content="Play the Connections Game with UNLIMITED levels! New challenging games are added each day. Find the common connections between groups of words."
          />
        </Helmet> */}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;

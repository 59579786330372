import { Grid, Typography } from "@mui/material";
import { Footer } from "./Footer";

export const HomePageDescription = () => {
  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={0} sm={0} md={0} lg={2} xl={2}></Grid>
        <Grid container item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ m: "2rem" }}>
            <h1 style={{ marginBottom: "1rem" }}>Connections Game</h1>
            <Typography>
              Playing the Connections Game is a fun and challenging way to test
              your ability to spot patterns and links between seemingly
              unrelated words. This word game forces you to reconsider the
              meaning of words in the context of other words.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ m: "2rem", mb: "1rem" }}
          >
            <h2 style={{ marginBottom: "1rem" }}>
              Understanding the Basics of Connections
            </h2>
            <Typography>
              Connections is a puzzle game where your goal is to identify groups
              of four words that share a common attribute. Here's how you can
              play:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "2rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#f3f6fa",
                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  1. Select Your Set:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Review the given words and choose a set of four words that you
                believe are connected.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  2. Make Your Guess:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Once you've chosen your set of words, submit your answer to find out if you're correct.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  3. Avoid Errors:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` You must find the correct groups without making more than four incorrect guesses.`}</span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "1rem",
            }}
          >
            <h2 style={{ marginBottom: "1rem" }}>
              Identifying Categories in Connections
            </h2>
            <Typography>
              Groups of words in the Connections Game are categorized based on
              shared characteristics. Here are some examples:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "2rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#f3f6fa",
                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  BRANCHES OF SCIENCE:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Such as Biology, Mathematics, Botany, Chemistry`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  WORDS ENDING IN “FISH”:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Gold, Stone, Parrot, Gar. (i.e., Goldfish, Stonefish, Parrotfish, Garfish).`}</span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
            }}
          >
            <h3 style={{ marginBottom: "1rem" }}>
              Strategies for Solving Connections Puzzles
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "2rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#f3f6fa",
                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Look for the Obvious:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Start with what stands out. Some categories are straightforward and easier to spot.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Consider Multiple Categories:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Some items might seem to fit into more than one group. However there is only one correct way to solve the game.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Watch for Tricky Connections:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` The game includes 'tricky' categories that require creative thinking and a broad range of knowledge.`}</span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "1rem",
            }}
          >
            <h2 style={{ marginBottom: "1rem" }}>
              Difficulty Levels in Connections
            </h2>
            <Typography>
              As you play the Connections Game you'll notice that each group of
              items is color-coded to indicate the level of difficulty:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "2rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#f3f6fa",
                borderRadius: "8px",
                padding: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Yellow:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Straightforward and easier to solve.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Green:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` A moderate level of challenge.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Blue:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Harder and more challenging.`}</span>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Purple:{" "}
                  <span
                    style={{ fontWeight: 400 }}
                  >{` Tricky and the most challenging.`}</span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              ml: "2rem",
              mr: "2rem",
              mt: "1rem",
              mb: "1rem",
            }}
          >
            <h2 style={{ marginBottom: "1rem" }}>Final Thoughts</h2>
            <Typography sx={{ mb: "0.5rem" }}>
              Playing the Connections Game is not only entertaining but also a
              great exercise for your brain. It enhances your pattern
              recognition skills, expands your vocabulary, and teaches you to
              think laterally.
            </Typography>
            <Typography>
              With practice, you'll get better at identifying the links between
              words and enjoy the satisfaction of solving even the trickiest of
              puzzles. So why wait? Dive into "Connections" and start playing
              today!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

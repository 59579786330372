import { useUnityContext, Unity } from "react-unity-webgl";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerHeight: height, innerWidth: width } = window;
  return {
    height,
    width,
  };
}

const LoadGame = ({ appTunesMetaHeight }) => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "../assets/BallBlaster.loader.js",
    dataUrl: "../assets/BallBlaster.data.unityweb",
    frameworkUrl: "../assets/BallBlaster.framework.js.unityweb",
    codeUrl: "../assets/BallBlaster.wasm.unityweb",
    matchWebGLToCanvasSize: false,
  });
  return (
    <>
      <Grid container>
        {!isLoaded ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              height: isIOS
                ? windowDimensions.height - 180 - appTunesMetaHeight
                : windowDimensions.height - 140 - appTunesMetaHeight,
            }}
          >
            <CircularProgress
              variant="determinate"
              value={Math.round(loadingProgression * 100)}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            mb: isAndroid || isIOS ? "0" : "0",
          }}
        >
          <Unity
            unityProvider={unityProvider}
            style={{
              // height: "100%",
              // width: "100%",
              width: windowDimensions.width,
              height: isAndroid
                ? windowDimensions.height - 100
                : isIOS
                ? windowDimensions.height - 180 - appTunesMetaHeight
                : windowDimensions.height - 140,
            }}
            devicePixelRatio={window.devicePixelRatio}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LoadGame;

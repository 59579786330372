import { Box } from "@mui/material";
import ButtonImg from "../assets/Button.jpg";

export const AdCardBanner = () => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const BannerDiv = () => (
    <div
      onClick={() => {
        const appLinks = {
          ios: "https://apps.apple.com/cy/app/connections-game-world-puzzle/id6465991134",
          android:
            "https://play.google.com/store/apps/details?id=com.megarama.connections&hl=en&gl=US",
          webBrowser: "https://connections-magarama.onelink.me/7vfW/qlx1xay4",
        };

        if (isAndroid) {
          window.location.href = appLinks.android;
        } else if (isIOS) {
          window.location.href = appLinks.ios;
        } else {
          window.location.href = appLinks.webBrowser;
        }
      }}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <img src={ButtonImg} alt="App Link" style={{ height: 100 }} />
    </div>
  );

  return (
    <>
      <BannerDiv />
    </>
  );
};

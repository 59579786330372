import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import LoadGame from "../components/LoadGame";
import { AdCardBanner } from "../components/AdCardBanner";
import { HomePageDescription } from "../components/HomePageDescription";
import { CookieBanner } from "../components/CookieBanner";
import { Footer } from "../components/Footer";
import Cookies from "js-cookie";

function getWindowDimensions() {
  const { innerHeight: height, innerWidth: width } = window;
  return {
    height,
    width,
  };
}

export const Home = () => {
  // const [cookies] = useCookies(["cookieConsent"]);
  const [openCookieBanner, setOpenCookieBanner] = useState(
    Cookies.get("CookieConsent") || false
  );
  console.log(Cookies.get("CookieConsent"), "document.cookie.CookieConsent");
  const [appTunesMetaHeight, setAppTunesMetaHeight] = useState(0);

  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const [startGame, setStartGame] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const closeCookieBanner = () => setOpenCookieBanner(true);

  useEffect(() => {
    const checkAppleItunesAppMetaTag = () => {
      const initialHeight = window.innerHeight;
      const checkSmartBanner = () => {
        const currentHeight = window.innerHeight;
        const reducedHeight = initialHeight - currentHeight;
        setAppTunesMetaHeight(reducedHeight);
      };
      window.addEventListener("resize", checkSmartBanner);
      setTimeout(() => {
        window.removeEventListener("resize", checkSmartBanner);
      }, 3000);
    };
    checkAppleItunesAppMetaTag();

    // const intervalId = setInterval(() => {
    //   checkAppleItunesAppMetaTag();
    // }, 1000);
    // return () => {
    //   window.removeEventListener("resize", checkSmartBanner);
    // };
  }, []);
  console.log("smart banner height", appTunesMetaHeight);
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  const handleClick = () => {
    setStartGame(true);
  };
  return (
    <>
      <Grid container>
        {/* {!startGame && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              height: isAndroid
                ? windowDimensions.height - 100
                : isIOS
                ? windowDimensions.height - 100 - appTunesMetaHeight - 100
                : windowDimensions.height,
            }}
          >
            <PlayGameButton handleClick={handleClick} />
            <Typography variant="h1">Play Game</Typography>
          </Grid>
        )} */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // alignContent: "center",
            height: isIOS
              ? windowDimensions.height - 180 - appTunesMetaHeight
              : windowDimensions.height - 140 - appTunesMetaHeight,
          }}
        >
          <LoadGame appTunesMetaHeight={appTunesMetaHeight} />
        </Grid>
        {/* {isAndroid || isIOS ? ( */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            pl: "1rem",
          }}
        >
          {<AdCardBanner />}
        </Grid>
        {/* ) : null} */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <HomePageDescription />
        </Grid>
        {!openCookieBanner && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CookieBanner handleClose={closeCookieBanner} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            ml: "2rem",
            mr: "2rem",
            mt: "1rem",
            mb: openCookieBanner
              ? "1rem"
              : isAndroid || isIOS
              ? "10rem"
              : "5rem",
          }}
        >
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

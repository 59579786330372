import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  darken,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

export const CookieBanner = ({ handleClose }) => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  // const [closeCheck, setCloseCheck] = useState(false);
  // const [cookies, setCookie] = useCookies(["cookieConsent"]);
  // const addCookieConsent = () => {
  //   setCookie("cookieConsent", true, { path: "/" });
  // };
  // const rejectCookieConsent = () => {
  //   setCloseCheck(true);
  // };
  return (
    <>
      <CookieConsent
        onAccept={handleClose}
        buttonStyle={{
          borderRadius: "50%",
          fontSize: "1.5rem",
          backgroundColor: "#F3F6FA",
          "&:hover": {
            backgroundColor: darken("#F3F6FA", 0.3),
          },
        }}
        buttonText="x"
        style={{
          flexDirection: isAndroid || isIOS ? "column-reverse" : "row",
          backgroundColor: "#F3F6FA",
          borderTop: "1px solid rgba(0,63,123,.1)",
          textAlign: "center",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexFlow: "row nowrap",
          //flexDirection: "column-reverse",
        }}
      >
        <Grid container>
          <Grid item xs={0} sm={0} md={0} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Typography
              sx={{
                color: "#6E7C8C",
                fontSize: "0.8rem",
                //paddingBottom: "1.3rem",
              }}
            >
              We and our partners store and/or access information (including
              personal data such as device identifiers in cookies) on your
              device to provide, measure, analyze, improve our services and
              personalize ads and content. For more information or to change
              your preferences at any time go to the{" "}
              <Link
                to="https://megarama.net/cookie-policy-eu/"
                style={{ textDecoration: "none" }}
              >
                Cookie Policy
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </CookieConsent>
    </>
  );
};
